import axios from 'axios'


export const baseUrl = `https://apinorte.quomarketing.ar/public/api`
export const imageBase = `https://norte.quomarketing.ar`

// export const imgUrl = ``
export const HTTP = axios.create({
    baseURL: baseUrl,
    headers: {
        'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`,
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        // 'Content-Type': 'multipart/form-data' ,
        // 'Content-Type': 'application/x-www-form-urlencoded'

    },
    crossDomain: true
})
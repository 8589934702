<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #111111;
  margin: 0px auto;
  /* max-width: 1170px; */
  padding: auto;
  overflow-x: hidden;
}
body,
html {
  background: #fff;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  min-height: 100vh;
}
.modalFix2 .el-dialog {
  width: 100%;
}

.modalFix2 {
  width: 600px;

  margin: 0 auto;
}
.danger .is-checked .el-checkbox-button__inner {
  color: #fff;
  background-color: #eb13a3 !important;
  border-color: #eb13a3 !important;
  box-shadow: -1px 0 0 0 #eb13a3 !important;
}
.el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #dcdfe6;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0px !important;
}
.el-table--mini .el-table__cell {
  padding: 4px 0;
}
.el-table tr {
  background-color: #fff;
  cursor: pointer;
}


.el-table__body tr.current-row > td.el-table__cell {
  background-color: #ffecb3 !important;
}
.el-table--striped
  .el-table__body
  tr.el-table__row--striped.current-row
  td.el-table__cell {
  background-color: #ffecb3 !important;
}
.current-row {
  background-color: #ffecb3 !important;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #e91e63 !important;
  outline: 0;
}

/* input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {

  -webkit-appearance: none;
  margin: 0;
} */

.el-progress-bar__outer {
  height: 6px;
  border-radius: 0px !important;
  background-color: #ebeef5;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.el-progress-bar__inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #409eff;
  text-align: right;
  border-radius: 0px !important;
  line-height: 1;
  white-space: nowrap;
  transition: width 0.6s ease;
  color: 0px;
  font-weight: bold;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: #3f51b5 !important;
  border-color: #3f51b5 !important;
  color: #fff;
}
.el-button--mini {
  font-size: 12px;
  border-radius: 0 !important;
}

.el-button--gobernador {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #fff !important;
  font-weight: bold !important;
  border-right: 1px solid #fff !important;
}

.el-button--gobernador:focus,
.el-button--gobernador:hover {
  background: #6d0009 !important;
  border-color: #6d0009 !important;
  color: #fff !important;
}
.el-button--legislador {
  color: #fff !important;
  background-color: #757575 !important;
  border-color: #757575 !important;
  font-weight: bold !important;
}

.el-button--legislador:focus,
.el-button--legislador:hover {
  background: #535050 !important;
  border-color: #535050 !important;
  color: #fff !important;
}

.el-button--intendente {
  color: #fff !important;
  background-color: #673ab7 !important;
  border-color: #673ab7 !important;
  font-weight: bold !important;
}

.el-button--intendente:focus,
.el-button--intendente:hover {
  background: #38186e !important;
  border-color: #38186e !important;
  color: #fff !important;
}

.el-button--concejal {
  color: #fff !important;
  background-color: #673ab7 !important;
  border-color: #673ab7 !important;
  font-weight: bold !important;
}

.el-button--concejal:focus,
.el-button--concejal:hover {
  background: #38186e !important;
  border-color: #38186e !important;
  color: #fff !important;
}

.el-radio-button--mini .el-radio-button__inner {
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 0;
  /* width: 333px !important; */
  text-align: left;
}
.el-collapse-item__content {
  padding-bottom: 0px !important;
  font-size: 13px;
  color: #303133;
  line-height: 1.769230769230769;
  width: 100%;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eceeef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eceeef;
}

.table tbody + tbody {
  border-top: 2px solid #eceeef;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #eceeef;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #eceeef;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff0d8;
}

.table-hover .table-success:hover {
  background-color: #d0e9c6;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d0e9c6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d9edf7;
}

.table-hover .table-info:hover {
  background-color: #c4e3f3;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c4e3f3;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf8e3;
}

.table-hover .table-warning:hover {
  background-color: #faf2cc;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #faf2cc;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2dede;
}

.table-hover .table-danger:hover {
  background-color: #ebcccc;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ebcccc;
}

.thead-inverse th {
  color: #fff;
  background-color: #292b2c;
}

.thead-default th {
  color: #464a4c;
  background-color: #eceeef;
}

.table-inverse {
  color: #fff;
  background-color: #292b2c;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #fff;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive.table-bordered {
  border: 0;
}
</style>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :sm="{ span: 8, offset: 8 }" :offset="0">
        <el-card
          style="margin-top: 150px; min-height: 350px"
          shadow="never"
          :body-style="{ padding: '20px' }"
        >
          <div slot="header">
            <span>Acceso restringido</span>
          </div>
          <el-form
            :label-position="'top'"
            style="padding: 0px 30px"
            :model="login"
            ref="login"
          >
            <el-form-item
              label="Usuario"
              prop="user"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="login.user"
                @keypress.native.enter="submitForm('login')"
              />
            </el-form-item>
            <el-form-item
              label="Contraseña"
              prop="pass"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="login.pass"
                show-password
                @keypress.native.enter="submitForm('login')"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                :loading="procesando"
                @click="submitForm('login')"
                class="btn-block"
                >Ingresar
              </el-button>
            </el-form-item>
          </el-form>

          <!-- <div v-if="!numero">
            <p>Ingrese un número de teléfono autorizado</p>
            <MazPhoneNumberInput
              v-model="phoneNumber"
              @update="checkNumber"
              :default-country-code="'AR'"
              :preferred-countries="['AR', 'AR']"
              :translations="{
                countrySelectorLabel: 'Cod. país',
                countrySelectorError: 'Seleccione un país',
                phoneNumberLabel: 'Número de teléfono',
                example: 'ejemplo :',
              }"
            />
          </div>
          <div class="otpcontent" v-if="numero">
            <h5>Ingrese el código de verificación recibido en {{ numero }}</h5>
            <otp-input
             v-loading.fullscreen.lock="fullscreenLoading"
              ref="otpInput"
              input-classes="otp-input"
              separator="-"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
            />
            <h2 style="margin:20px auto">

             <el-checkbox v-model="recordar">Confiar en este navegador</el-checkbox> 
            </h2>
            <el-button
              type="info"
              :loading="enviando"
              :disabled="counting"
              size="default"
              style="margin-top: 10px; float: right"
              @click="enviarCodigo"
            >
              <span v-if="!counting">Renviar código</span>
              <vue-countdown
                v-if="counting"
                :time="60000"
                @end="onCountdownEnd"
                v-slot="{ totalSeconds }"
                >Renviar código en {{ totalSeconds }} segundos</vue-countdown
              >
            </el-button>
          </div> -->
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { HTTP } from "@/axios";
// import OtpInput from "@bachdgvn/vue-otp-input";
// import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  name: "LoginView",
  components: {
    // OtpInput,
    // VueCountdown: VueCountdown,
  },
  data() {
    return {
      procesando: false,
      login: {
        user: window.localStorage.getItem("vUser"),
        pass: window.localStorage.getItem("vPass"),
      },
      enviando: false,
      numero: "",
      code: "",
      counting: true,
      fullscreenLoading: false,
      phoneNumber: null,
      initPhoneNumberExample: "",
      results: null,
      recordar: 0,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.procesando = true;
          HTTP.post("/login", this.login)
            .then((res) => {
              console.log("login externo", res);
              this.procesando = false;
              if (res.data) {
                window.sessionStorage.setItem("norteFotos", 1);
                window.sessionStorage.setItem("datos", JSON.stringify(res.data));
                window.sessionStorage.setItem("vUser", this.login.user);
                window.sessionStorage.setItem("vPass", this.login.pass);
                window.localStorage.setItem("vUser", this.login.user);
                window.localStorage.setItem("vPass", this.login.pass);
                this.$router.push({ name: "inicio" });
              }
            })
            .catch((error) => {
              this.procesando = false;
              this.error = error;
              this.$alert("Error al iniciar Session " + error);
            });
        } else {
          // console.log("error submit!!");
          this.procesando = false;
          return false;
        }
      });
    },
    checkNumber($event) {
      // results = $event
      this.result = $event;
      console.log($event);
      if ($event.isValid) {
        this.numero = $event.e164;
        this.enviarCodigo();
      } else {
        this.numero = "";
      }
    },
    enviarCodigo() {
      let sesion = window.localStorage.getItem("numero");
      if (this.numero) {
        this.enviando = true;
        if (sesion == this.numero) {
          this.$router.push({ name: "inicio" });
        } else {
          HTTP.post("/enviarCodigo", { numero: this.numero.substring(1) })
            .then((res) => {
              // this.code = res.data.code
              //  window.sessionStorage.setItem('datos', this.data.row)
              if (!res.data.code) {
                this.$alert(
                  "No pudimos enviar el código de verificación, refresque la página y reintente",
                  { type: "error" }
                );
              }
              this.enviando = false;
            })
            .catch(() => {
              this.enviando = false;

              this.$alert(
                "No pudimos enviar el código de verificación, refresque la página y reintente",
                { type: "error" }
              );
            });
        }
      } else {
        this.$alert("Número de telefono inválido o incorrecto", { type: "error" });
      }
    },
    handleOnComplete(value) {
      console.log("OTP completed: ", value);
      console.log("OTP code: ", this.code);
      // if(this.code == value)
      // {
      this.fullscreenLoading = true;
      HTTP.post("/verificarCodigo", {
        code: value,
        numero: this.numero.substring(1),
        recordar: this.recordar,
      })
        .then((res) => {
          if (res.data) {
            if (this.recordar) {
              window.localStorage.setItem("norteFotos", this.numero.substring(1));
            }
            window.sessionStorage.setItem("norteFotos", this.numero.substring(1));
            window.sessionStorage.setItem("datos", JSON.stringify(res.data.row));
            this.fullscreenLoading = false;
            this.$router.push({ name: "inicio" });
          } else {
            this.$alert("No se pudo verificar el código, reintente");
          }
        })
        .catch(() => {
          this.$alert("No se pudo verificar el código, reintente");
        });
      // }
      // else{
      //   this.$alert('El código ingresa no es correcto, solicite uno nuevo', {type:'error'})
      // }
    },
    handleOnChange(value) {
      console.log("OTP changed: ", value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    startCountdown: function () {
      this.counting = true;
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
  },
};
</script>

<style lang="scss">
.otpcontent {
  margin: 20px auto;
  width: 500px;
  text-align: center;
  align-items: center;
  align-content: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import {

  MazPhoneNumberInput
} from 'maz-ui'
import Croppa from 'vue-croppa'
import VueApexCharts from 'vue-apexcharts'

import VueNumber from 'vue-number-animation'


Vue.use(VueNumber) 
Vue.use(VueApexCharts)
Vue.use(MazPhoneNumberInput)
Vue.use(Croppa)     

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

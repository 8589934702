import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'inicio',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/InicioView.vue')
  },
  {
    path: '/marcadas',
    name: 'marcadas',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/marcadas.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin.vue')
  },
  {
    path: '/foto/:id',
    name: 'foto',
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "about" */ '../views/Foto.vue')
  }


]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  // console.log(to);
  if (to.matched.some(record => record.meta.requiresAuth)) {

    let auth = 1
    console.log('at', auth)
    if (!auth) {
      next({
        path: '/',
        query: { origen: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})



export default router
